export default [
  {
    header: 'COORDENAÇÃO',
    resource: 'usuários',
    action: 'menu',
  },
  {
    title: 'Configurações',
    icon: 'SettingsIcon',
    children: [],
  },
]
