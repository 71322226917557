export default [
  {
    title: 'Permissões2',
    icon: 'SettingsIcon',
    tag: '2',
    tagVariant: 'light-warning',
    action: 'listar',
        subject: 'permissões',
  
 
    children: [
      {
        title: 'eCommerce2',
        route: 'dashboard-ecommerce',
        action: 'listar',
        subject: 'permissões',
      },
      
    ],
  },
]
