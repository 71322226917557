export default [
  {
    header: 'COORDENAÇÃO',
    resource: 'usuários',
    action: 'menu',
  },
  {
    title: 'Configurações',
    icon: 'SettingsIcon',
    children: [

      {
        title: 'Subdisciplinas',
        route: 'subdisciplinas',
        icon: 'LayersIcon',
        resource: 'subdisciplinas',
        action: 'menu',

      },

    ],
  },

]
