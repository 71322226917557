export default [
  {
    header: 'Colaborador',
    resource: 'chats',
    action: 'listar',
  },

  {
    title: 'Propostas',
    route: 'propostas',
    icon: 'ClipboardIcon',
    resource: 'propostas',
    action: 'menu',

  },
  {
    title: 'Chat',
    route: 'chat',
    icon: 'MessageSquareIcon',
    resource: 'chats',
    action: 'listar',

  },
  {
    title: 'Clientes',
    route: 'clientes',
    icon: 'CoffeeIcon',
    resource: 'clientes',
    action: 'menu',

  },
  {
    title: 'Leads',
    route: 'leads',
    icon: 'UserPlusIcon',
    resource: 'leads',
    action: 'menu',

  },

]
