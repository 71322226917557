export default [
  {
    header: 'COORDENAÇÃO',
    resource: 'usuários',
    action: 'menu',
  },
  {
    title: 'Configurações',
    icon: 'SettingsIcon',
    children: [],
  },

  {
    title: 'Usuários',
    route: 'usuários',
    icon: 'UsersIcon',
    resource: 'usuários',
    action: 'menu',

  },

]
