export default [
  {
    header: 'Colaborador',
    resource: 'atividades',
    action: 'listar',
  },
  {
    title: 'Chat',
    route: 'chat',
    icon: 'MessageSquareIcon',
    resource: 'chats',
    action: 'listar',

  },


]
