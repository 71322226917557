export default [
  {
    header: 'Colaborador',
    resource: 'atividades',
    action: 'listar',
  },
  {
    title: 'Atividades',
    route: {
      name: 'apps-todo-filter',
      params: { filter: 'Em_Desenvolvimento' },
    },
    icon: 'CheckSquareIcon',
    resource: 'atividades',
    action: 'listar',
  },
  {
    title: 'Chat',
    route: 'chat',
    icon: 'MessageSquareIcon',
    resource: 'chats',
    action: 'listar',

  },
  {
    title: 'Clientes',
    route: 'clientes',
    icon: 'CoffeeIcon',
    resource: 'clientes',
    action: 'menu',

  },

  {
    title: 'Projetos',
    route: 'projetos',
    icon: 'GridIcon',
    resource: 'projetos',
    action: 'listar',

  },
  {
    title: 'Times',
    route: 'times',
    icon: 'ShieldIcon',
    resource: 'times',
    action: 'listar',
  },
]
