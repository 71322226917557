<template>
  <b-nav-item-dropdown
      v-if="qtdNotifications > 0"
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
  >
    <template #button-content>
      <feather-icon
          :badge="qtdNotifications"
          badge-classes="bg-danger"
          class="text-body"
          icon="MessageSquareIcon"
          size="21"
      />
    </template>
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Chat
        </h4>
        <b-badge
            pill
            variant="light-primary"
        >
          {{ computedNotifications.length }} nova(s)
        </b-badge>
      </div>
    </li>
    <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
    >
      <b-link
          v-for="notification in computedNotifications"
          :key="notification.id"
      >
        <b-media>
          <template #aside>
            <b-avatar
                size="32"
                :src="notification.avatar"
                :text="notification.avatar"
                :variant="notification.type"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder font-small-3">
              {{ notification.fullName }}
            </span>
          </p>
          <small class="notification-time">{{
              notification.chat.last_message.time
            }} - </small>
          <small class="notification-text">{{
              notification.chat.last_message.message
            }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>
    <b-dropdown-item
        :to="{ name: 'chat'}"
    >
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="text"
          block
      >Ver Chat
      </b-button>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { atualizarNoficacoesChat } from '@/@core/mixins/atualizacoes'
import { BAvatar, BBadge, BButton, BDropdownItem, BLink, BMedia, BNavItemDropdown, } from 'bootstrap-vue'
import { computed, ref, watch } from '@vue/composition-api'
import moment from 'moment-timezone'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BDropdownItem,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,

  },
  directives: {
    Ripple,
  },
  setup() {
    const computedNotifications = ref([])
    const qtdNotifications = ref(0)

    const notificacoesChat = computed(() => store.state.app.notificacoesChat)
    watch(notificacoesChat, newVal => {

      if (newVal) {
        computedNotifications.value = newVal.filter(item => item.chat.unseenMsgs > 0)
        qtdNotifications.value = newVal.filter(item => item.chat.unseenMsgs > 0).length
      }
    })

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    const convertStringToDate = dateString => moment.tz(dateString, 'ddd MMM D YYYY HH:mm:ss', 'America/Sao_Paulo')
        .format('DD/MM/YY HH:mm')

    atualizarNoficacoesChat()
    if (store.state.app.chatChannel) {
      store.state.app.chatChannel.bind('message', data => {
        atualizarNoficacoesChat()
      })
    }

    return {
      computedNotifications,
      qtdNotifications,

      perfectScrollbarSettings,
      convertStringToDate,
    }
  },
}
</script>
