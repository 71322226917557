import obra from './obra'
import projeto from './projetos'

const modules = {
  projeto,
  obra,
}

export default function notificacoesFactory({
  module,
  component,
  event,
}) {
  if (modules[module] && modules[module][component] && modules[module][component][event]) {
    return modules[module][component][event]
  }
  return ''
}
