/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import appsAndPages from './apps-and-pages'
import chartsAndMaps from './charts-and-maps'
import dashboard from './dashboard'
import formAndTable from './forms-and-table'
import menuAdmin from './adm'
import menuComercial from './comercial'
import menuEngenharia from './engenharia'
import menuExecucao from './execucao'
import menuInicial from './inicial'
import menuRhdp from './rhdp'
import others from './others'
import sam from './sam'
import uiElements from './ui-elements'

const isLocalhost = window.location.href.includes('local')

let menusDisponiveis = [...sam]

const userStore = JSON.parse(localStorage.getItem('userData'))

const userPreferenceStore = userStore?.preferences

const userSetorStore = userStore?.setor
const setorAtual = userPreferenceStore && userPreferenceStore.setorAtual
const menuSetorAtual = setorAtual || userSetorStore

switch (menuSetorAtual) {
  case 'comercial':
    menusDisponiveis = [...sam, ...menuComercial]
    break
  case 'engenharia':
    menusDisponiveis = [...sam, ...menuEngenharia]
    break
  case 'execução':
    menusDisponiveis = [...sam, ...menuExecucao]
    break
  case 'adm':
    menusDisponiveis = [...sam, ...menuAdmin]
    break
  case 'rhdp':
    menusDisponiveis = [...sam, ...menuRhdp]
    break
  default:
    menusDisponiveis = [...menuInicial]
}

if (isLocalhost) {
  // Menus para Desenvolvimento diponiveis
  menusDisponiveis = [
    ...menusDisponiveis,
    ...uiElements,
    ...appsAndPages,
    ...formAndTable,
    ...chartsAndMaps,
    ...dashboard,
    ...others,
  ]
}

export default menusDisponiveis
