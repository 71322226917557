<template>
  <b-nav-item-dropdown

      ref="dropdownRef"
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
  >

    <template #button-content>
      <feather-icon
          :badge="computedQtdNotifications"
          badge-classes="bg-danger"
          class="text-body"
          icon="BellIcon"
          size="21"
      />
    </template>

    <div v-if="computedNotifications.length > 0">
      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            Últimas Notificações
          </h4>

        </div>
      </li>

      <!-- Notifications -->
      <vue-perfect-scrollbar
          ref="scrollRef"
          :settings="perfectScrollbarSettings"
          class="scrollable-container media-list scroll-area"
          tagname="li"
          @ps-y-reach-end="loadMoreData"
      >

        <b-link
            v-for="notification in computedNotifications"
            :key="notification.id"
        >
          <b-media @click="goToNotification(notification)">
            <template #aside>
              <b-avatar
                  size="32"
                  :variant="notification.type"
              >
                <feather-icon
                    v-if="notification"
                    size="28"
                    :icon="notification.icon"
                />
              </b-avatar>
            </template>

            <div class="d-flex justify-content-between">
              <div
                  class="font-weight-bold mr-1"
              >
                {{ notification.title }}
              </div>
              <div v-if="!notification.read">
                <feather-icon
                    v-b-tooltip.hover.top="`Não lida`"
                    icon="BellIcon"
                    size="15"
                />
              </div>
            </div>

            <small class="notification-text font-small-3">{{ notification.description }}</small>
            <div class="d-flex justify-content-end font-weight-light font-small-2 mt-1 text-gray">
              <div>{{ mascaraDataBr(notification.created_at) }}</div>

            </div>
          </b-media>
        </b-link>
      </vue-perfect-scrollbar>

      <!-- Cart Footer -->
      <li class="dropdown-menu-footer">

        <b-button
            v-if="computedQtdNotifications > 0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            @click="marcarTodasLidas()"
        >Marcar todas como lidas.
        </b-button>
      </li>
    </div>
    <div v-else>
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div class="font-weight-bold mr-1">
              Nenhuma notificação
            </div>

          </div>
        </div>
      </div>
    </div>

  </b-nav-item-dropdown>
</template>

<script>
import { atualizarNoficacoes, lerNotificacao } from '@core/mixins/atualizacoes'
import { BAvatar, BButton, BLink, BMedia, BNavItemDropdown, VBTooltip, } from 'bootstrap-vue'
import { mascaraDataBr } from '@core/utils/filter'
import notificacoesFactory from '@core/utils/factories/notificacoes'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import store from '@/store'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BNavItemDropdown,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      paginaAtual: 1,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      notificationsList: [],
      qtdNotifications: 0,
      contadorLoadMore: 0,
      updatePusher: false,

    }
  },
  computed: {
    computedNotifications() {
      return this.notificationsList
    },
    computedQtdNotifications() {
      return this.qtdNotifications
    },
  },
  created() {
    this.buscarNotificacoes()
  },
  mounted() {
    if (store.state.app.notificacoesChannel) {
      store.state.app.notificacoesChannel.bind('novo-comentario', data => {
        this.$refs.dropdownRef.hide()
        this.resetCampos()
        this.scrollToTop()

        this.buscarNotificacoes()
            .then(() => {
              this.scrollToTop()
              this.contadorLoadMore = 2
              this.paginaAtual = 2
            })
      })
    }
    this.ajutarContadorLoadMore()
  },

  methods: {
    mascaraDataBr,
    ajutarContadorLoadMore() {
      setTimeout(() => {
        this.contadorLoadMore = 2
      }, 3000)
    },
    scrollToTop() {
      this.$refs.scrollRef.$el.scrollTop = 0
    },
    resolveIcon(type) {
      switch (type) {
        case 'success':
          return 'CheckIcon'
        case 'danger':
          return 'XIcon'
        case 'warning':
          return 'AlertTriangleIcon'
        case 'info':
          return 'InfoIcon'
        default:
          return 'BellIcon'
      }
    },
    resetCampos() {
      this.notificationsList = []
      store.state.app.notificacoes = []
      store.state.app.notificacoesMeta = []
      this.contadorLoadMore = 2
      this.paginaAtual = 1
    },
    async marcarTodasLidas() {
      await lerNotificacao()
      this.$refs.dropdownRef.hide()
      this.qtdNotifications = 0
      this.resetCampos()
      this.scrollToTop()

      this.buscarNotificacoes()
          .then(() => {
            this.contadorLoadMore = 2
            this.paginaAtual = 2
          })
    },
    goToNotification(notification) {
      lerNotificacao(notification.id)
      if (router.currentRoute.path !== notification.link.url) {
        this.$refs.dropdownRef.hide()
        router.push(notification.link.url)
            .catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                throw err
              }
            })
      }
    },
    montarNotificacoes(data) {

      const newList = data.data.map(item => {

        const link = this.montaLinkNotificacao(item)

        return link ? {
          id: item.id,
          title: item.title,
          description: item.description,
          read: item.read,
          icon: this.resolveIcon(item.meta.type),
          type: item.type,
          created_at: item.created_at,
          link,
        } : {}

      })
          .filter(item => item.id)

      this.notificationsList = this.notificationsList.concat(newList)
     
      this.qtdNotifications = newList.filter(item => item.read === false).length
    },
    montaLinkNotificacao(item) {
      const method = notificacoesFactory({
        module: item.module.toLowerCase(),
        component: item.component.toLowerCase(),

        event: item.event.toLowerCase(),
      })

      return typeof method === 'function' ? method(item.meta) : method
    },

    async buscarNotificacoes(params) {
      await atualizarNoficacoes(params)
          .then(data => {
            this.montarNotificacoes(data)
            this.paginaAtual = data.meta.current_page + 1

            if (data.meta.current_page > 1) {
              this.contadorLoadMore = 2
            }
          })
    },
    loadMoreData() {
      if (this.paginaAtual !== store.state.app.notificacoesMeta.current_page && this.contadorLoadMore === 2 && this.paginaAtual <= store.state.app.notificacoesMeta.last_page) {
        this.buscarNotificacoes({ page: this.paginaAtual })
        this.contadorLoadMore = 1
      }
      this.contadorLoadMore += 1
    },
  },
}
</script>
