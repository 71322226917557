<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- <b-button v-if="this.exibeBtnVoltar" variant="link" size="sm" :to="previousRoute"> <feather-icon icon="ArrowLeftIcon"
        size="20" /> </b-button> -->
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-chat-dropdown class="pr-1" />

      <notification-dropdown class="pr-1" />
      <setores-dropdown
        v-if="$can('escolher', 'setores')"
        class="pr-1"
      />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BNavItemDropdown } from 'bootstrap-vue'
import SetoresDropdown from '@core/layouts/components/app-navbar/components/SetoresDropdown.vue'
import NotificationChatDropdown from './components/NotificationChatDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BNavItemDropdown,
    SetoresDropdown,
    BLink,
    NotificationDropdown,
    // Navbar Components
    BNavbarNav,
    NotificationChatDropdown,

    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      previousRoute: null,
      exibeBtnVoltar: false,
    }
  },
  computed: {
    linkAnterior() {
      return ''
    },
  },
  watch: {
    $route(to, from) {
      this.previousRoute = from.path
      this.exibeBtnVoltar = to.path.split('/').length === 4
    },
  },
  created() {
    this.previousRoute = this.$route.path
  },
}
</script>
