export default [
  {
    header: 'Acesso SAM',
  },
  {
    title: 'Perfis',
    route: 'perfis',
    icon: 'SettingsIcon',
    resource: 'perfis',
    action: 'listar',
  },
  {
    title: 'Permissões',
    route: 'permissões',
    icon: 'UserPlusIcon',
    resource: 'permissões',
    action: 'listar',
  },

]
