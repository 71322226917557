export default [
  {
    header: 'COORDENAÇÃO',
    resource: 'usuários',
    action: 'menu',
  },
  {
    title: 'Configurações',
    icon: 'SettingsIcon',
    children: [

      {
        title: 'Descrições de Registros',
        route: 'registros',
        icon: 'BookIcon',
        resource: 'registros',
        action: 'menu',
      },
      {
        title: 'Especificações da Obra',
        route: 'especificacoes',
        icon: 'TargetIcon',
        resource: 'especificacoes',
        action: 'menu',
      },

    ],
  },

  {
    title: 'Obras',
    route: 'obras',
    icon: 'ClipboardIcon',
    resource: 'obras',
    action: 'menu',
  },

]
