import store from '@/store'

export const atualizarNoficacoesChat = async () => {
  await store.dispatch('app/updateChatNotifications')
}

export const atualizarNoficacoes = async params => await store.dispatch('app/updateNotifications', params)
  .then(data => data)

export const lerMensagemChat = async chatId => {
  await store.dispatch('app/readMessageChat', chatId)
  atualizarNoficacoesChat()
}
export const lerNotificacao = async chatId => {
  await store.dispatch('app/readNotification', chatId)
  // atualizarNoficacoes()
}

export const atualizarSessaoUsuario = async () => {
  await store.dispatch('app/updateUserSession')
}
export const _ = null
